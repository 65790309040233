import React from "react";
import { Oval } from "react-loader-spinner";
import PropTypes from "prop-types";

export default function Loading({ height = 20 }) {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Oval
        color="blue"
        height={height}
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#ffffff"
        strokeWidth={4}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

Loading.propTypes = {
  height: PropTypes.number,
};
