/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples

import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import a1 from "assets/images/a1.jpg";
import a2 from "assets/images/i drive.jpg";
import a3 from "assets/images/a3.jpeg";
import a4 from "assets/images/a4.avif";
import a5 from "assets/images/health outreach.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={a1}
              description="Improve the health and safety of Central Florida tourists and tourism workers through surveillance and data-supported programs."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={a2}
              description="Guide tourism-related businesses to prevent and respond to health and safety threats."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={a3}
              imageStyle={{ height: "13rem" }}
              description="Provide local and state governments information to guide policy development and the effective use of public funds to improve tourist and tourism worker health and safety."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: 3 }}>
            <CenteredBlogCard
              image={a4}
              description="Create a nationally recognized externally funded research program to improve tourist and tourism worker health and safety."
            />
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: 3 }}>
            <CenteredBlogCard
              image={a5}
              description="Provide student and community education to improve the health and safety of tourists and tourism workers."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
