/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";

// Material Kit 2 React examples

import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes

import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/signin1.jpg";
import { useMutation } from "@apollo/client";
import { SIGN_IN } from "graphql/mutation";
import { useState } from "react";
import ErrorComponent from "components/errorComponent";
import Loading from "components/Loading";

function ContactUs() {
  const [validationError, setValidationError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logIn, { loading, error }] = useMutation(SIGN_IN);
  const handleSignIn = async () => {
    try {
      if (!email || !password) {
        setValidationError(true);
        return;
      } else {
        setValidationError(false);
      }
      console.log("logf", logIn);
      const data = await logIn({ variables: { email, password } });
      console.log("daa", data);
      localStorage.setItem("email", data.data.login.user.email);
      localStorage.setItem(
        "name",
        data.data.login.user.firstName + " " + data.data.login.user.lastName
      );
      localStorage.setItem("token", data.data.login.token);
      navigate("/");
    } catch (error) {}
  };
  console.log("eerir", error?.message);
  const navigate = useNavigate();
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Sign In
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              {error && (
                <ErrorComponent message={error?.message || "An error occured while  Signing In"} />
              )}
              {validationError && <ErrorComponent message={"Please complete all the fields"} />}
              <MKTypography variant="body2" color="text" mb={3}></MKTypography>
              <MKBox width="100%" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      type="email"
                      label="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type="password"
                      label="Password"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5}>
                  <MKButton onClick={handleSignIn} type="submit" variant="gradient" color="info">
                    {loading ? <Loading /> : "LOGIN"}
                  </MKButton>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={1} mb={2}>
                  <div
                    onClick={() => navigate("/sign-up")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <MKTypography variant="body2"> Don&apos;t have an account ?</MKTypography>
                    <MKButton
                      type="button"
                      variant="text"
                      color="info"
                      sx={{ paddingLeft: "0.5rem" }}
                    >
                      SIGN UP
                    </MKButton>
                  </div>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
