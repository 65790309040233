import React from "react";
import PropTypes from "prop-types";

import MKBox from "./MKBox";
import MKAlert from "components/MKAlert";

export default function ErrorComponent({ message }) {
  return (
    <MKBox>
      <MKAlert color="error">{message}</MKAlert>
    </MKBox>
  );
}

ErrorComponent.propTypes = {
  message: PropTypes.string,
};
