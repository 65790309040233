/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
/* import Featuring from "pages/LandingPages/AboutUs/sections/Featuring"; */
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
import LogoAbout from "assets/aboutus.webp";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          !localStorage.getItem("name")
            ? {
                type: "internal",
                route: "/sign-up",
                label: "Sign UP",
                color: "info",
              }
            : null
        }
        sticky
      />
      <MKBox
        minHeight="95vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ),url(${LogoAbout})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={{ marginBottom: "1rem", marginTop: "0rem" }}
            >
              Background and Mission Statements
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              opacity={0.8}
              mt={1}
              mb={3}
            >
              The tourism sector profoundly influences various aspects of life in our region, the
              UCF Center on Infectious Disease and Tourism Health will generate a wide-reaching
              positive impact throughout the community. The overarching objective of the initiative
              is to bring together key stakeholders from the realms of Infectious Disease and
              Tourism. The goal of the Initiative is to expand into a prominent Center recognized on
              a national level.
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="left"
              opacity={0.8}
              mt={1}
              mb={3}
            >
              The envisioned Center will host facilities for the CDC and the Florida Department of
              Health, alongside offices for major players in the travel industry. Research,
              education and knowledge dissemination activities of the initiative include the
              analysis of data, development of interventions, commercialization of the biomedical
              science innovations, coordination with local, state, federal, global government
              agencies, hospitals, community organizations, travel associations and other
              stakeholders interested in improving health resilience to infectious disease
              outbreaks. The initiative evaluates its success on relevance, impact and usefulness of
              its activity.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
        {/*   <Featuring /> */}
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default AboutUs;
