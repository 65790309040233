/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/rc.jpeg";
import team2 from "assets/images/gf.jpeg";
import team3 from "assets/images/ak1.jpeg";

import team4 from "assets/eric.jpg";
import team5 from "assets/Azarian.jpg";

//import team4 from "assets/images/ivana-square.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Founders and Members of IDTH
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Dr. Robertico Croes"
                position={{ color: "info", label: "Professor" }}
                description={
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://hospitality.ucf.edu/person/robertico-croes/"
                  >
                    View Details
                  </a>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Dr. Griff Parks"
                position={{ color: "info", label: "Associate Dean, Research Director" }}
                description={
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://med.ucf.edu/biomed/person/dr-griff-parks/"
                  >
                    View Details
                  </a>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Dr. Alexander Kenneth"
                position={{ color: "info", label: "Professor" }}
                description={
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://med.ucf.edu/person/kenneth-a-alexander/"
                  >
                    View Details
                  </a>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Dr. Eric Schrimshaw"
                position={{
                  color: "info",
                  label: "Chair, Professor of Medicine",
                }}
                description={
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href="https://med.ucf.edu/person/eric-schrimshaw-phd/"
                  >
                    View Details
                  </a>
                }
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team5}
                name="Dr. Taj Azarian"
                position={{ color: "info", label: "Assistant Professor" }}
                description={
                  <a
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://med.ucf.edu/biomed/person/dr-taj-azarian/"
                  >
                    View Details
                  </a>
                }
              />
            </MKBox>
          </Grid>
          {/*  <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Marquez Garcia"
                position={{ color: "info", label: "JS Developer" }}
                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
