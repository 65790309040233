/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/LandingPages/AboutUs/sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
/* import Featuring from "pages/LandingPages/AboutUs/sections/Featuring"; */
import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";
import LogoAbout from "assets/aboutus.webp";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images

function ProvertyRates() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          !localStorage.getItem("name")
            ? {
                type: "internal",
                route: "/sign-up",
                label: "Sign UP",
                color: "info",
              }
            : null
        }
        sticky
      />
      <MKBox
        minHeight="45vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ),url(${LogoAbout})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={{ marginBottom: "1rem", marginTop: "0rem" }}
            >
              County Poverty Rate , Individual Income and Intention to Leave Hospitality industry (
              Size of Circle )
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <iframe src="https://socket.crccanvas.us/" style={{ height: "100vh", width: "100%" }} />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ProvertyRates;

{
  /*  */
}
