/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import coinbase from "assets/file.jpg";
//import nasa from "assets/images/logos/gray-logos/logo-nasa.svg";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import LogoTeam from "assets/team.webp";

// Images

function TeamAndOrganization() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={
          !localStorage.getItem("name")
            ? {
                type: "internal",
                route: "/sign-up",
                label: "Sign UP",
                color: "info",
              }
            : null
        }
        sticky
      />
      <MKBox
        minHeight="95vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45) ),url(${LogoTeam})`,

          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={{ marginBottom: "1rem", marginTop: "0rem" }}
            >
              Conceptual Model
            </MKTypography>
            <MKBox
              component="img"
              sx={{ borderRadius: "12px", maxHeight: "35rem" }}
              src={coinbase}
              alt="coinbase"
              width="100%"
              opacity={0.7}
            />
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" pt={3} pb={8}>
          <Container>
            <Grid container spacing={3} sx={{ mb: 12 }}>
              <Grid item xs={12} md={12} lg={12}>
                <MKBox sx={{ marginBottom: "0.5rem" }}>
                  <CenteredBlogCard
                    descriptionColor="black"
                    description="The Initiative establishes a collaborative platform that brings together
                  expertise, resources, and influence from diverse UCF colleges, including Rosen
                  Hospitality Management, Engineering and Computer Science, and Medicine. Partners
                  from Nursing, Health Profession Sciences, Academic Health Science Center, as well
                  as clusters like Coastal and Genomics."
                  />
                </MKBox>
                <MKBox sx={{ marginBottom: "0.5rem" }}>
                  <CenteredBlogCard
                    descriptionColor="black"
                    description="Serving as a hub for partners and the
                  community, the Initiative provides well-founded ideas and strategies to tackle
                  challenges and empower the community, aiming to bolster industry and community
                  resilience. Leveraging UCF's strengths, the Center's vision draws on its
                  identity as &quot;America's Partnership University,&quot; tapping into its
                  reach in health care, hospitality, tourism, and engineering. Rosen College's
                  reputation in research further amplifies its impact on a global scale."
                  />
                </MKBox>

                <MKBox sx={{ marginBottom: "0.5rem" }}>
                  <CenteredBlogCard
                    descriptionColor="black"
                    description="External
                  collaborators encompass local governments, tourism leaders, and advocacy groups
                  related to resorts, hotels, restaurants, and travel associations. Institutions
                  like Nemours Children's Hospital and Florida Departments of Health are key
                  contributors. The airport and cruise terminal also play pivotal roles. Research
                  spans critical topics at the intersection of Infectious Disease and Tourism,
                  including biology of emerging pathogens, vaccine development, outbreak modeling,
                  and pharmaceutical advances. "
                  />
                </MKBox>

                <MKBox sx={{ marginBottom: "0.5rem", textAlign: "left" }}>
                  <CenteredBlogCard
                    descriptionColor="black"
                    description="Addressing health disparities, social determinants,
                  and community-industry dynamics, the research explores behavioral economics and
                  psychological aspects during pandemics. Engineering and computer science delve
                  into bioinformatics, pathogenic outbreak simulation, AI, and machine learning for
                  rapid therapy design. Innovations for pathogen deactivation in commercial settings
                  are pursued."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TeamAndOrganization;
