// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import Logo from "assets/images/logo.JPG";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Material Kit 2",
    image: Logo,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "IDTH",
      items: [
        { name: "About US", route: "/about-us" },
        {
          name: "Team and Organization",

          route: "/team-and-organization",
        },
        {
          name: "Collaboration",
          internal: true,
          route: "/",
        },
        { name: "Sign Up/Sign In", route: "/sign-up" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "Presentation", href: "https://iradesign.io/" },
        { name: "Publications", href: "https://www.creative-tim.com/bits" },
        {
          name: "County Poverty Rate , Individual Income and Intention to Leave Hospitality industry ( Size of Circle )",
          route:
            "/county-poverty-rate-individual-income-and-intention-to-leave-hospitality-industry-size-of-circle",
        },
      ],
    },
    {
      name: "Help & Support",
      items: [
        { name: "contact us", href: "https://www.creative-tim.com/contact-us" },
        { name: "Knowledge Center", href: "https://www.creative-tim.com/knowledge-center" },
        { name: "Partnership", href: "https://services.creative-tim.com/" },
        { name: "Funding", href: "https://www.creative-tim.com/sponsorships" },
      ],
    },
    {
      name: "Legal",
      items: [
        { name: "Terms & Conditions", href: "https://www.creative-tim.com/terms" },
        { name: "Privacy Policy", href: "https://www.creative-tim.com/privacy" },
        { name: "Licenses (EULA)", href: "https://www.creative-tim.com/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} IDTH
      {/*  <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography> */}
      .
    </MKTypography>
  ),
};
