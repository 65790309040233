import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String
    $password: String!
    $roleId: Int!
    $jwt: String
  ) {
    signup(
      email: $email
      firstName: $firstName
      lastName: $lastName
      password: $password
      roleId: $roleId
      jwt: $jwt
    ) {
      firstName
      lastName
      email
      id
    }
  }
`;

export const SIGN_IN = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        email
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation ($jwt: String!) {
    verifyEmail(jwt: $jwt) {
      token
      user {
        email
        id
        firstName
        lastName
        email
      }
    }
  }
`;
