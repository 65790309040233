/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useNavigate, useSearchParams } from "react-router-dom";

// Material Kit 2 React examples

import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes

import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/signup.jpg";
import SelectVariants from "../signup/select";
import Loading from "components/Loading";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROLES } from "../../../graphql/query";
import ErrorComponent from "components/errorComponent";
import { useEffect, useState } from "react";
import { SIGN_UP } from "graphql/mutation";

const validatePassword = (password) => {
  const criteria = {
    length: password.length >= 8,
    number: /\d/.test(password),
    specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  return {
    isValid: Object.values(criteria).every(Boolean),
    criteria,
  };
};

const validateEmail = (email) => {
  // Regular expression for validating email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

function ContactUs() {
  const [validationError, setValidationError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState(null);
  const [emailReadOnly, setEmailReadOnly] = useState(false);
  const { loading, error, data } = useQuery(GET_ROLES);
  // eslint-disable-line no-unused-vars
  const [signup, { loading: signUpLoading, error: signUpError }] = useMutation(SIGN_UP);
  const navigate = useNavigate();

  const [validation, setValidation] = useState({ isValid: false, criteria: {} });

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const hasEmail = searchParams.get("email");
    if (hasEmail) {
      setEmail(hasEmail);
      setEmailReadOnly(true);
    }
  }, []);

  const isInvalid = (value) => {
    if (!value) return true;
    return false;
  };

  const handleSignUp = async () => {
    try {
      const fields = [firstName, email, password, roleId];
      const invalid = fields.filter((item) => isInvalid(item)).length > 0;

      if (invalid) {
        setValidationError("Please complete all the fields");
        return;
      }
      setValidationError(false);
      const { isValid, criteria } = validatePassword(password);

      if (!isValid) {
        if (!criteria.length) {
          setValidationError("Password shuld be At least 8 characters long");
        } else if (!criteria.number) {
          setValidationError("Password shuld be contain a number");
        } else {
          setValidationError("Password shuld be contain a special character");
        }
        return;
      }

      if (!validateEmail(email)) {
        setValidationError("Email address is not valid");
        return;
      }

      const signUpData = await signup({
        variables: {
          firstName,
          lastName,
          password,
          email,
          roleId: parseInt(roleId),
          jwt: searchParams.get("jwt") || null,
        },
      });

      localStorage.setItem("verificationEmailSent", "true");
      navigate("/verification");
    } catch (error) {}
  };

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Sign Up
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              {error && (
                <ErrorComponent message={error.message || "An error occured while getting roles"} />
              )}
              {validationError && <ErrorComponent message={validationError} />}
              {signUpError && (
                <ErrorComponent
                  message={signUpError.message || "An error occurred while signing up"}
                />
              )}

              <MKTypography variant="body2" color="text" mb={3}></MKTypography>
              <MKBox width="100%" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      errorText={"fnwekf"}
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      label="First Name*"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      label="Last Name"
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      type="email"
                      label="Email*"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={emailReadOnly ? "disabled" : ""}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKInput
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      type="password"
                      label="Password*"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    {loading ? (
                      <Loading />
                    ) : (
                      <SelectVariants
                        onChange={(value) => setRoleId(value)}
                        value={roleId}
                        roles={data.roles}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton onClick={handleSignUp} type="submit" variant="gradient" color="info">
                    {signUpLoading ? <Loading /> : "SIGN UP"}
                  </MKButton>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={1} mb={2}>
                  <div
                    onClick={() => navigate("/sign-in")}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <MKTypography variant="body2"> Already have an account ?</MKTypography>
                    <MKButton
                      type="button"
                      variant="text"
                      color="info"
                      sx={{ paddingLeft: "0.5rem" }}
                    >
                      SIGN IN
                    </MKButton>
                  </div>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
