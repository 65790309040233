/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import MKTypography from "components/MKTypography";

// Material Kit 2 React examples

import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes

import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/signup.jpg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MKButton from "components/MKButton";
import Icon from "@mui/material/Icon";
import { SiMicrosoftoutlook, SiGmail } from "react-icons/si";
import { Chip, Divider } from "@mui/material";
import Loading from "components/Loading";
import { useMutation } from "@apollo/client";
import { VERIFY_EMAIL } from "graphql/mutation";
import ErrorComponent from "components/errorComponent";

function VerifyEmail() {
  const [verifyEmail, { loading, error }] = useMutation(VERIFY_EMAIL);
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const jwt = urlParams.get("jwt");
    if (!jwt) {
      navigate("/");
      return;
    }
    callVerifyEmailMutation(jwt);
  }, []);

  const callVerifyEmailMutation = async (jwt) => {
    try {
      const { data } = await verifyEmail({ variables: { jwt } });
      localStorage.clear();
      localStorage.setItem("email", data.verifyEmail.user.email);
      localStorage.setItem(
        "name",
        data.verifyEmail.user.firstName + " " + data.verifyEmail.user.lastName
      );
      localStorage.setItem("token", data.verifyEmail.token);
    } catch (error) {
      console.log("ee", error);
    }
  };

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Email being verified
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              {loading ? (
                <Loading height={40} />
              ) : error ? (
                <ErrorComponent
                  message={error?.message || "An error occurred while verifying email"}
                />
              ) : (
                <>
                  <MKTypography variant="body2" color="text" mb={3}>
                    Your email has been verified successfully
                  </MKTypography>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MKButton
                      onClick={() => navigate("/", "_blank")}
                      variant="gradient"
                      color="info"
                      style={{ marginRight: "1rem" }}
                    >
                      Home
                    </MKButton>
                  </div>
                </>
              )}
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default VerifyEmail;
